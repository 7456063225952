import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ChatUserList from './ChatUserList'; // Import the ChatUserList component
import ChatMessageDetail from './ChatMessageDetail';

import { useLazyGetUserChatHistoryQuery } from '../../redux/thunks/users/userchathistory';
import { useWebSocket } from '../../WebSockets/WebSocketProvider';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const otherUser = location.state?.otherUser || '';

  const [selectedUser, setSelectedUser] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check if screen is mobile
  const [isUserListVisible, setIsUserListVisible] = useState(true);

  const userId = useSelector((state) => state.auth.userId);
  const displayName = useSelector((state) => state.auth.user.displayName);
  const profileImageUrl = useSelector((state) => state.auth.user.profileImageUrl);

  const [fetchChatHistory, { data: chatHistoryData, isLoading: chatHistoryLoading }] = useLazyGetUserChatHistoryQuery();
  const { socket } = useWebSocket();

  useEffect(() => {
    if (chatHistoryData) {
      setChatHistory(chatHistoryData.messages);
    }
  }, [chatHistoryData]);

  useEffect(() => {
    const handleIncomingMessage = (event) => {
      const socketData = JSON.parse(event.data);
      if (socketData.body.type === 'CHAT_MESSAGE' && socketData.body.subType === 'RECEIVE') {
        const newMessage = transformWebSocketDataForRendering(socketData.body);
        setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
      }
    };

    if (socket) {
      socket.addEventListener('message', handleIncomingMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener('message', handleIncomingMessage);
      }
    };
  }, [socket]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update isMobile state on window resize
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const transformWebSocketDataForRendering = (socketData) => {
    const { sender, text, displayName, profileImageUrl, msgId } = JSON.parse(socketData.data);
    return {
      msgId,
      text,
      msgTime: new Date(),
      profileImageUrl,
      displayName,
      sender,
    };
  };

  const handleUserClick = async (user) => {
    setSelectedUser(user);
    setIsUserListVisible(false); // Hide user list on mobile when a user is clicked
    await fetchChatHistory(user.otherUserId);
  };

  const handleBackToUserList = () => {
    setIsUserListVisible(true); // Show user list on mobile when back button is clicked
  };

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (messageInput.trim() !== '') {
      const ephemeralNewMessage = {
        text: messageInput,
        displayName,
        profileImageUrl,
        msgId: (Math.floor(100000 + Math.random() * 900000)).toString(),
        msgTime: new Date(),
      };
      setChatHistory((prevChatHistory) => [...prevChatHistory, ephemeralNewMessage]);

      if (socket) {
        const payload = {
          type: 'CHAT_MESSAGE',
          subType: 'SEND',
          data: {
            sender: userId,
            to: selectedUser.otherUserId,
            text: messageInput,
          },
        };
        socket.send(JSON.stringify(payload));
      }

      setMessageInput('');
    }
  };

  if (chatHistoryLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      <Grid container sx={{ border: '0px solid #e0e0e0', p: 1, mb: 2, marginTop: '20px' }} spacing={2}>
        {/* User List */}
        {(!isMobile || (isMobile && isUserListVisible)) && (
          <Grid item xs={12} sm={4} sx={{ p: 1, bgcolor: 'white' }}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="h6">Messages</Typography>
                  <MoreHorizIcon />
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: '#f5f5f5',
                  }}
                  InputProps={{
                    startAdornment: <SearchIcon style={{ cursor: 'pointer' }} />,
                    endAdornment: <FilterListIcon style={{ cursor: 'pointer' }} />,
                  }}
                />
              </Grid>
              <Grid item>
                <ChatUserList onUserClick={handleUserClick} otherUser={otherUser} />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Chat Messages */}
        {(!isMobile || (isMobile && !isUserListVisible)) && (
          <Grid item xs={12} sm={8} sx={{ p: 1, bgcolor: 'white' }}>
            {isMobile && isUserListVisible === false && (
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer', mb: 1, color: 'blue' }}
                onClick={handleBackToUserList}
              >
                &larr; Back to Users
              </Typography>
            )}
            <Box
              sx={{
                maxHeight: 'calc(100vh - 150px)', // Make sure the height does not overlap with the input box
                overflowY: 'auto',
                '&::-webkit-scrollbar': { width: '0.4em' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: '#888' },
              }}
            >
              {chatHistory && chatHistory.length > 0 ? (
                chatHistory.map((message) => (
                  <ChatMessageDetail key={message.msgId} message={message} />
                ))
              ) : (
                <Typography variant="body1">No messages to display.</Typography>
              )}
            </Box>
            <Divider flexItem sx={{ marginY: 1 }} />
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              label="Type your message..."
              sx={{ backgroundColor: '#f5f5f5' }}
              value={messageInput}
              onChange={handleMessageChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Chat;

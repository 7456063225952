import React, { useEffect } from 'react';
import { Grid, Paper, Box, CircularProgress } from '@mui/material';
import { useGetUserProfileQuery } from '../../redux/thunks/users/userprofile';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSpFindMutation } from '../../redux/thunks/sp/spfind';
import { useSelector } from 'react-redux';


import UserFacePlate from './UserFacePlate';
import FirstDegreeFriends from './FirstDegreeFriends'; // Import FirstDegreeFriends
import ActivitySection from './ActivitySection';
import SearchComponent from "../../Components/SearchComponent/SearchComponent";
import EndorsementList from './EndorsementList';
import SimilarProfiles from './SimilarProfiles';




const UserProfile = () => {

  const { userId } = useParams(); 
  const navigate = useNavigate();
  console.log('in user profile, userId', userId);
  const token = useSelector((state) => state.auth.token)
  const { data: userData, isError, isLoading: isUserLoading, error } = useGetUserProfileQuery(userId);
  const [triggerSearch, { data: searchResults, isLoading: isSearchLoading }] = useSpFindMutation();
  console.log('in user profile, userData', userData);

  useEffect(() => {
    if (searchResults) {
      navigate(`/search/providers/results`, { state: { searchResults } });
    }
  }, [searchResults, navigate]);

  const handleSearch = (searchText, location) => {
    // Handle the search with searchText and location
    console.log('Search Text:', searchText);
    console.log('Location:', location);
    let auth = {
      token: token,
      authType: 'token'
    }
    const payload = {
      auth,
      text: searchText
    };

    triggerSearch({ payload });
  };

  if (isUserLoading) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress color="primary" />
      </Box>

    )
  }

  return (

    <Box maxWidth={1280} mx="auto" p={2}>


      {/* Three Columns Layout */}
      <Grid container spacing={1}>
        {/* First Column (25% Width) */}
        <Grid item xs={12} sm={4} md={3}>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
            {userData && <UserFacePlate user={userData} />}
          </Paper>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
            <FirstDegreeFriends friends={userData.firstDegreeFriends} />
          </Paper>

          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
            <EndorsementList endorsements={userData.myTrustedThings} />
          </Paper>

        </Grid>

        {/* Second Column (50% Width) */}
        <Grid item xs={12} sm={6} md={6}>
          <Paper variant="outlined" sx={{ border: 'none', bgcolor: '#F8F9F9' }}>
            <ActivitySection id={userId} activity={userData.myActivities} />
          </Paper>
        </Grid>

        {/* Third Column (25% Width) */}
        <Grid item xs={12} sm={4} md={3}>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}>
            Reserved for user stats card
          </Paper>
          <Paper variant="outlined" sx={{
            padding: '10px', border: 'none', mb: 1, borderRadius: 2, maxHeight: '35%', overflowY: 'auto', '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888', // Color of the scrollbar thumb
              borderRadius: '10px', // Rounded corners for the thumb
              '&:hover': {
                backgroundColor: '#555', // Darker color when hovered
              },
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1', // Background of the track
              borderRadius: '10px', // Rounded corners for the track
            },
          }}>
            <SimilarProfiles user={userData} />
          </Paper>


        </Grid>
      </Grid>
    </Box>
  );
};

export default UserProfile;

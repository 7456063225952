import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Typography, Rating, Chip, Button, Stack, IconButton } from '@mui/material';
import styled from '@emotion/styled';  // Changed import
import { PhotoCamera, Add } from '@mui/icons-material';

import { useSpReviewPostMutation } from '../../redux/thunks/sp/spreviewpost';
import { useGenerateTextMutation } from '../../redux/thunks/ai/generatetext';
import { useLocation, useNavigate } from 'react-router-dom';

// Styled components using @emotion/styled
const ResponsiveLayout = styled(Box)`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
  padding: ${props => props.theme.spacing(2)};
  
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

const ColumnCard = styled(Card)`
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[1]};
  
  ${props => props.theme.breakpoints.down('md')} {
    width: 100% !important;
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

const ReviewTextArea = styled('textarea')`
  width: 100%;
  min-height: 200px;
  padding: ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.palette.divider};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.body1.fontSize};
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.palette.primary.main};
  }
  
  ${props => props.theme.breakpoints.down('sm')} {
    min-height: 150px;
  }
`;

const PhotoGrid = styled(Stack)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(1)};
  
  & img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    
    ${props => props.theme.breakpoints.down('sm')} {
      width: 80px;
      height: 80px;
    }
  }
`;

// Predefined options
const POSITIVE_EXPERIENCES = [
  'Great Service',
  'Quality Products',
  'Clean Space',
  'Good Value',
  'Professional',
  'Friendly Staff',
];

const IMPROVEMENT_AREAS = [
  'Wait Time',
  'Pricing',
  'Service Speed',
  'Product Availability',
];

const KEYWORDS = [
  'Friendly',
  'Fast',
  'Professional',
  'Modern',
  'Traditional',
  'Cozy',
];

export const ReviewWriting = ({ businessId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedbackProps = location.state;
  console.log('in write review feedbackProps: ', feedbackProps);  
  // Redux state and mutations
  const [uploadPhoto] = useUploadPhotoMutation();

  const [createReview, { isLoading: isReviewLoading }] = useSpReviewPostMutation();
  const [generateText, { isLoading: isgenerateTextLoading }] = useGenerateTextMutation();

  // Local state
  const [rating, setRating] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [selectedPositive, setSelectedPositive] = useState([]);
  const [selectedImprovement, setSelectedImprovement] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = useSelector((state) => state.auth.token);

  // Handle chip selection
  const handleChipClick = (chip, type) => {
    switch (type) {
      case 'positive':
        setSelectedPositive(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
      case 'improvement':
        setSelectedImprovement(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
      case 'keyword':
        setSelectedKeywords(prev => 
          prev.includes(chip) ? prev.filter(item => item !== chip) : [...prev, chip]
        );
        break;
    }
  };

  // Handle photo upload
  const handlePhotoUpload = async (event) => {
    const files = event.target.files;
    if (!files) return;

    const newPhotos = Array.from(files);
    setPhotos(prev => [...prev, ...newPhotos]);

    try {
      const uploadPromises = newPhotos.map(photo => uploadPhoto({ photo, businessId }).unwrap());
      const urls = await Promise.all(uploadPromises);
      setPhotoUrls(prev => [...prev, ...urls]);
    } catch (error) {
      console.error('Error uploading photos:', error);
      // Add error handling UI feedback here
    }
  };

  // Handle review submission
  const handleSubmit = async () => {
    if (!rating) return;

    setIsSubmitting(true);
    try {

        let auth = {
            token: token,
            authType: 'token',
          };
          let review = {
            review: reviewText,
            rating: rating,
            skillId: feedbackProps.skillId,
            businessId: feedbackProps.businessId
          };
  
          const payload = {
            auth,
            review,
            spId: feedbackProps.spId,
            positiveExperiences: selectedPositive,
             improvementAreas: selectedImprovement,
             keywords: selectedKeywords,
             photos: photoUrls,
          };
      await createReview({payload }).unwrap();
      // Add success handling here (redirect or show success message)
    } catch (error) {
      console.error('Error submitting review:', error);
      // Add error handling UI feedback here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ResponsiveLayout>
      {/* Business Info Card */}
      <ColumnCard sx={{ width: 300 }}>
        <CardContent>
          <Typography variant="h6">{feedbackProps.skill.businessName}</Typography>
          <Typography color="textSecondary">{feedbackProps.skill.businessName}</Typography>
          <Typography color="textSecondary">{feedbackProps.skill.businessName}</Typography>
          
          {/* Mobile-only Rating Section */}
          <Box sx={{ display: { md: 'none' }, mt: 2 }}>
            <Typography gutterBottom>Overall Rating</Typography>
            <Rating 
              size="large"
              value={rating}
              onChange={(_, newValue) => setRating(newValue)}
            />
          </Box>
        </CardContent>
      </ColumnCard>

      {/* Main Review Content */}
      <Box sx={{ flex: 1 }}>
        <ColumnCard>
          <CardContent>
            {/* Desktop-only Rating Section */}
            <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 3 }}>
              <Typography gutterBottom>Overall Rating</Typography>
              <Rating 
                size="large"
                value={rating}
                onChange={(_, newValue) => setRating(newValue)}
              />
            </Box>

            {/* Experience Chips */}
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>What did you like?</Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                {POSITIVE_EXPERIENCES.map(exp => (
                  <Chip
                    key={exp}
                    label={exp}
                    onClick={() => handleChipClick(exp, 'positive')}
                    color={selectedPositive.includes(exp) ? 'primary' : 'default'}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Stack>
            </Box>

            {/* Similar sections for Improvement Areas and Keywords... */}

            {/* Review Text */}
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Write Your Review</Typography>
              <ReviewTextArea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder="Share your experience..."
              />
            </Box>

            {/* Photo Upload */}
            <Box sx={{ mb: 3 }}>
              <Typography gutterBottom>Add Photos</Typography>
              <PhotoGrid>
                <IconButton
                  color="primary"
                  component="label"
                  sx={{ 
                    width: { xs: '80px', sm: '100px' }, 
                    height: { xs: '80px', sm: '100px' } 
                  }}
                >
                  <input
                    hidden
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handlePhotoUpload}
                  />
                  <Add />
                </IconButton>
                {photoUrls.map((url, index) => (
                  <img 
                    key={index} 
                    src={url} 
                    alt={`Upload ${index + 1}`}
                  />
                ))}
              </PhotoGrid>
            </Box>

            {/* Submit Button */}
            <Button
              variant="contained"
              fullWidth
              disabled={!rating || isSubmitting}
              onClick={handleSubmit}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Review'}
            </Button>
          </CardContent>
        </ColumnCard>
      </Box>

      {/* AI Assistant - Desktop */}
      <ColumnCard sx={{ width: 300, display: { xs: 'none', md: 'block' } }}>
        <CardContent>
          <Typography variant="h6">AI Assistant</Typography>
          {/* AI assistance features */}
        </CardContent>
      </ColumnCard>

      {/* AI Assistant - Mobile */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ColumnCard>
          <CardContent>
            <Typography variant="h6">AI Assistant</Typography>
            {/* Mobile AI assistance features */}
          </CardContent>
        </ColumnCard>
      </Box>
    </ResponsiveLayout>
  );
};
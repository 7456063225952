import React from 'react';
import { FieldArray, Field } from 'formik';
import { TextField, Typography, IconButton, Grid, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FindMeAtPage = () => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Where Can We Find You?
      </Typography>
      <FieldArray name="socialLinks">
        {({ push, remove, form }) => (
          <div>
            {form.values.socialLinks.map((link, index) => (
              <Paper key={index} elevation={0} sx={{ p: 2, mb: 2, border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name={`socialLinks[${index}].name`}
                      label="Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      as={TextField}
                      name={`socialLinks[${index}].link`}
                      label="Link"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}
            <IconButton
              onClick={() => push({ name: '', link: '' })}
              color="primary"
              sx={{
                fontSize: "14px",
                '&:hover': {
                  color: 'blue', // Change icon color on hover
                  transform: 'scale(1.1)', // Slightly scale up the button on hover
                  transition: 'all 0.2s ease-in-out', // Smooth transition for the hover effect
                },
              }}
            >
              Add Link
            </IconButton>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default FindMeAtPage;

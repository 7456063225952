import React from "react";
import { Container, Box, Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F8F9F9",
        padding: "20px 0",
        pt: 5,
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            flexWrap: "wrap",
            textAlign: { xs: "center", sm: "left" },
            borderTop: "1px solid #efefef",
            pt: 3,
          }}
        >
          {[
            "About",
            "Help Center",
            "Privacy",
            "Legal",
            "Blogs",
            "Apps",
            "Social",
          ].map((text) => (
            <Link
              key={text}
              href={`/${text.toLowerCase()}`}
              color="inherit"
              sx={{
                m: 1,
                fontSize: "0.875rem",
                textDecoration: "none",
              }}
            >
              {text}
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/revfer/skyblue.svg"
            alt="Revfer Logo"
            style={{ width: "30px", height: "15px" }}
          />
          <Typography variant="body2" sx={{ ml: 1, fontSize: "0.75rem" }}>
            Revfer &copy; {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Stack,
  Paper,
  Autocomplete,
  Box,
  IconButton,
  ClickAwayListener,
} from '@mui/material';

import { useEditBusinessMutation } from '../../redux/thunks/sp/editbusiness';
import { useGetAllThingsQuery } from '../../redux/thunks/things/thingsget';
import { spApi } from '../../redux/thunks/sp/sp';
import { useNavigate } from 'react-router-dom';
import FileUpload from '../../Components/FilesUpload/FileUpload';
import FullscreenLoader from '../../Components/FilesUpload/FullscreenLoader';
import { useGetPreSignedUrlQuery } from '../../redux/thunks/files/filespresignurl'; // Import your RTK query

const EditBusinessPhoto = ({ isOpen, onClose, businessDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loaderMessage = 'Updating..';
  const [businessName, setBusinessName] = useState(businessDetails.name);
  const [businessId, setBusinessId] = useState(businessDetails._id);

  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const thisSpId = useSelector((state) => state.auth.user.spId);

  console.log('businessDetails ', businessDetails);

  const { data: presignedUrlData, isLoading: isPresignedUrlLoading } =
    useGetPreSignedUrlQuery(businessId);
  console.log('presignedUrlData: ', presignedUrlData);
  console.log('isLoading: ', isLoading);

  useEffect(() => {
    setBusinessName(businessDetails.name);
    setBusinessId(businessDetails._id);
  }, [businessDetails]);

  const handleFileChange = (file) => {
    setSelectedImage(file);
  };

  const handleUpload = async () => {
    if (!selectedImage) return; // No image selected

    if (presignedUrlData) {
      const presignedUrl = presignedUrlData.url; // Extract the presigned URL
      const contentType = selectedImage.type; // e.g., 'image/jpeg', 'image/png', etc.

      // Set the Content-Type header in the fetch request
      const headers = {
        'Content-Type': contentType,
      };

      try {
        // Use the Fetch API to send a PUT request to the presigned URL
        await fetch(presignedUrl, {
          method: 'PUT',
          body: selectedImage,
          headers,
        });

        // Image successfully uploaded
        console.log('Image uploaded successfully!!!');

        // Show fullscreen loader after successful upload
        setIsLoading(true);

        // Simulate a delay for x seconds
        const loaderTimeout = 5000; // Time in milliseconds
        const prefetchTimeout = 2000; // Time in milliseconds

        setTimeout(async () => {
          // Prefetch using RTK toolkit query during the second delay
          console.log('Image uploaded successfully!!!111');

          //prefetchGetSpProfile(args)

          // Wait for the second delay (prefetchTimeout)
          setTimeout(() => {
            // Navigate after the second delay

            console.log('Image uploaded successfully!!!222');

            navigate(`/provider/profile/${thisSpId}`, {
              state: {
                businessId: businessId,
                businessName: businessName,
                skill: 'default',
              },
            });
            setIsLoading(false);

            onClose();
          }, prefetchTimeout);
        }, loaderTimeout);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleSubmit = async () => {
    console.log('values while submitting: businessName ', businessName);
    console.log('values while submitting: category ', category);
    console.log('values while submitting: dcategory ', dcategory);
    console.log('values while submitting: subCategories ', subCategories);
    console.log('values while submitting: address ', address);
    console.log('values while submitting: findMeAt ', findMeAt);

    const auth = {
      token: token,
      authType: 'token',
    };
    const data = {
      socialLinks: findMeAt,
      address: address,
      businessName: businessName,
    };

    const payload = {
      auth,
      skillId: category.skillId,
      otherSkillIds: subCategories.map((obj) => obj.skillId),
      spId: businessDetails.spId,
      data: data,
      businessId: businessDetails._id,
    };

    await postEdit({ payload });
    dispatch(
      spApi.util.invalidateTags([
        { type: 'Spprofile', id: businessDetails.spId },
      ])
    );
    navigate(`/provider/profile/${businessDetails.spId}`, {
      state: {
        businessId: businessDetails._id,
        businessName: businessName,
        skill: 'default',
      },
    });
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ width: '100%', height: '100%' }}
    >
      <DialogTitle>
        <Typography variant="h6">Change Photo</Typography>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <div>
            {!isLoading && (
              <div>
                <Typography variant="h6" gutterBottom>
                  Select a Photo
                </Typography>
                <FileUpload onFileChange={handleFileChange} />
                {selectedImage && (
                  <div>
                    <Typography variant="subtitle1">Selected Image:</Typography>
                    <img
                      src={URL.createObjectURL(selectedImage)}
                      alt="Selected"
                      style={imageStyles}
                    />
                  </div>
                )}
                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isPresignedUrlLoading}
                    onClick={handleUpload}
                    sx={{ mr: 2 }}
                  >
                    Upload Photo
                  </Button>
                  {/* New button for direct navigation */}
                  <Button variant="outlined" color="primary" onClick={onClose}>
                    Upload Later
                  </Button>
                </Box>
                <Typography variant="caption" gutterBottom>
                  or use the one we have selected
                </Typography>
                <div>
                  <img
                    src={businessDetails.photoUrl}
                    alt="Selected"
                    style={imageStyles}
                  />
                </div>
              </div>
            )}

            <div>
              {isLoading && <FullscreenLoader message={loaderMessage} />}
            </div>
          </div>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

const imageStyles = {
  maxWidth: '100%',
  maxHeight: '200px',
  marginTop: '16px',
};

export default EditBusinessPhoto;

import React from 'react';
import { Container, Grid, Box, Paper } from '@mui/material';

import MyFirstDegrees from './MyFirstDegrees';
import PendingInvites from './PendingInvites';
import SentInvites from './SentInvites';
import PhotoCard from '../../Components/ByteSize/PhotoCards/PhotoCard';
import Footer from '../../Components/Footer/Footer';
import { useSelector } from 'react-redux';

import UsersYouMayKnow from './UsersYouMayKnow';
import SearchBox from './SearchBox';

const MyNetwork = () => {
  const firstDegrees = useSelector(
    (state) => state.auth.user.firstDegreeFriends
  );
  const isListEmpty = firstDegrees.length === 0;
  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      {/* First Full-Width Row */}

      {/* Three Columns Row */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {isListEmpty ? (
            <Paper variant="outlined" sx={{ p: 2, border: 'none' }}>
              <PhotoCard type={'AddFriends'} />
            </Paper>
          ) : (
            <Paper
              variant="outlined"
              sx={{ p: 2, borderRadius: 2, border: 'none' }}
            >
              <MyFirstDegrees />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper
            variant="outlined"
            sx={{ p: 2, border: 'none', borderRadius: 2, mb: 0 }}
          >
            <SearchBox />
            {/* You can add your content here */}
          </Paper>
          <Paper
            variant="outlined"
            sx={{ p: 2, border: 'none', borderRadius: 2, mb: 1 }}
          >
            <PendingInvites />
            {/* You can add your content here */}
          </Paper>

          <Paper
            variant="outlined"
            sx={{ p: 2, border: 'none', borderRadius: 2, mb: 1 }}
          >
            <SentInvites />
            {/* You can add your content here */}
          </Paper>

          <Paper
            variant="outlined"
            style={{ p: 2, border: 'none', borderRadius: 2, mb: 1 }}
          >
            <UsersYouMayKnow />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper
            variant="outlined"
            sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
          >
            {/* Content for the third column */}
            <PhotoCard type={'NeedReview'} />
          </Paper>

          {/* <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9'}}>
            <Footer/>
          </Paper> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyNetwork;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { I18n } from '@aws-amplify/core';
import './Styles.css';

const SignIn = () => {
  const navigate = useNavigate();
  const { authStatus, error } = useAuthenticator((context) => [
    context.authStatus,
    context.error,
  ]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [key, setKey] = useState(0);

  useEffect(() => {
    I18n.putVocabularies({
      en: {
        'Sign in': 'Sign In',
        'Sign in to your account': 'Sign In',
        'Send code': 'Send Code',
      },
    });
  }, []);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home');
    } else if (error) {
      setEmail('');
      setPassword('');
      setKey((prev) => prev + 1);
    }
  }, [authStatus, navigate, error]);

  return (
    <div
      style={{
        minHeight: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Authenticator
        loginMechanisms={['email']}
        socialProviders={['facebook', 'google']}
        signUpAttributes={['family_name', 'given_name']}
        key={key}
      >
        {({ signOut, user }) => (
          <form>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your Email"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your Password"
            />
            <button type="button" onClick={() => signOut()}>
              Sign out
            </button>
            <div>User: {user ? JSON.stringify(user) : 'None'}</div>
          </form>
        )}
      </Authenticator>
    </div>
  );
};
export default SignIn;
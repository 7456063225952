import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlaceIcon from '@mui/icons-material/Place';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ResultsSection from '../ResultsSection';
import PhotoCard from "../../../Components/ByteSize/PhotoCards/PhotoCard";
import TextCard from "../../../Components/ByteSize/TextCards/TextCard";
import Footer from "../../../Components/Footer/Footer";
import FiltersSection from '../FiltersSection';
import SortSection from '../SortSection';
import GoogleMap from '../GoogleMapComponent';

import { usePrefetch as useGetUserCacheDataPrefetch } from '../../../redux/thunks/users/usercachedata';
import { useLazyGetUserCacheDataQuery } from '../../../redux/thunks/users/usercachedata';

const BusinessNameSerachResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  const searchResultsTmp = location.state?.searchResultsBusinessName || [];
  const cacheId = searchResultsTmp.cacheId;
  const searchResults = searchResultsTmp?.results || [];
  const searchParams = location.state?.searchParams || [];

  const prefetchGetUserCacheData = useGetUserCacheDataPrefetch('getUserCacheData');
  const [getCacheData, { data: resultsCacheData, isLoading: cacheLoading }] = useLazyGetUserCacheDataQuery();

  const pageSize = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredResults, setFilteredResults] = useState(searchResults.slice(0, pageSize));
  const [pagifiedResults, setPagifiedResults] = useState(searchResults.slice(0, pageSize));
  const [latestResults, setLatestResults] = useState(searchResults);

  const [showMap, setShowMap] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [showCachedResults, setShowCachedResults] = useState(false);
  const [fetchResultsTrigger, setFetchResultsTrigger] = useState(false);

  const handleDiscoverFromNetwork = () => {
    getCacheData(cacheId);
    setFetchResultsTrigger(!fetchResultsTrigger);
  };

  useEffect(() => {
    if (cacheId) {
      prefetchGetUserCacheData(cacheId);
    }
  }, [cacheId]);

  useEffect(() => {
    if (filteredResults) {
      setPagifiedResults(filteredResults.slice(0, pageSize));
    }
  }, [filteredResults]);

  useEffect(() => {
    if (resultsCacheData) {
      const uniqueObjectIdsArray = resultsCacheData.uniqueObjectIdsArray || [];
      const stringObjectIds = uniqueObjectIdsArray.map((objectId) => objectId.businessId.toString());

      const filteredLatestResults = latestResults.filter((business) =>
        stringObjectIds.includes(business._id)
      );

      setLatestResults(filteredLatestResults);
      setPagifiedResults(filteredLatestResults.slice(0, pageSize));
      setFilteredResults(filteredLatestResults);
      setShowCachedResults(true);
    }
  }, [resultsCacheData, fetchResultsTrigger]);

  const handleViewOnMapClick = () => {
    setShowMap(true);
    setSelectedBusinesses(pagifiedResults);
  };

  const handlePageChange = (newPage) => {
    const startIndex = (newPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPage(newPage);
    setPagifiedResults(filteredResults.slice(startIndex, endIndex));
  };

  const handleBackToResults = () => {
    setFilteredResults(searchResults.slice(0, pageSize));
    setPagifiedResults(searchResults.slice(0, pageSize));
    setShowCachedResults(false);
  };

  // Responsive design: check if the screen size is mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      {/* Display Google Map when showMap is true */}
      {showMap && selectedBusinesses.length > 0 && (
        <GoogleMap
          businesses={selectedBusinesses}
          onClose={() => {
            setShowMap(false);
            setSelectedBusinesses([]);
          }}
        />
      )}

      <Grid container spacing={1}>
        {/* First Column (Filters) */}
        <Grid item xs={12} sm={4} md={2}>
          <Paper variant="outlined" sx={{ padding: '10px', border: 'none', borderRadius: 2 }}>
            {/* Mobile Accordion for Filters */}
            {isMobile ? (
              <>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">Distance</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label="1 mile" />
                      <FormControlLabel control={<Checkbox />} label="5 miles" />
                      <FormControlLabel control={<Checkbox />} label="10 miles" />
                      <FormControlLabel control={<Checkbox />} label="50 miles" />
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">Star Rating</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label="1 Star" />
                      <FormControlLabel control={<Checkbox />} label="2 Stars" />
                      <FormControlLabel control={<Checkbox />} label="3 Stars" />
                      <FormControlLabel control={<Checkbox />} label="4 Stars" />
                      <FormControlLabel control={<Checkbox />} label="5 Stars" />
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <FiltersSection
                results={latestResults}
                setFilteredResults={setFilteredResults}
                filter={'generic'}
              />
            )}
          </Paper>
        </Grid>

        {/* Second Column (Results) */}
        <Grid item xs={12} sm={8} md={7}>
          <Paper variant="outlined" sx={{ padding: '0px', border: 'none', bgcolor: '#F8F9F9' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              sx={{
                bgcolor: 'white',
                padding: '15px',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                mb: 1,
                mt: 0,
                borderRadius: '30px',
              }}
            >
              {/* Showing 1 - 5 of 30 */}
              <Typography variant="body2" color="textSecondary">
                Showing {currentPage * pageSize - pageSize + 1} -{' '}
                {Math.min(currentPage * pageSize, filteredResults.length)} of {filteredResults.length}
              </Typography>

              {/* Buttons (Map, Insider, Back) */}
              <Box display="flex" gap={2}>
                <Button
                  variant="outlined"
                  startIcon={isMobile ? <PlaceIcon /> : <PlaceIcon />}
                  color="primary"
                  onClick={handleViewOnMapClick}
                >
                  {isMobile ? null : 'SHOW ON MAPS'}
                </Button>
                {showCachedResults ? (
                  <Button
                    variant="outlined"
                    startIcon={<ArrowLeftIcon />}
                    color="primary"
                    onClick={handleBackToResults}
                  >
                    Back to Results
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={isMobile ? <Diversity3Icon /> : <Diversity3Icon />}
                    color="primary"
                    onClick={handleDiscoverFromNetwork}
                  >
                    {isMobile ? null : 'INSIDERS'}
                  </Button>
                )}
              </Box>

              {/* Sort By */}
              <Box
                display="flex"
                sx={{
                  fontSize: isMobile ? '0.75rem' : '1rem', // Adjust font size for small screens
                  padding: isMobile ? '4px 8px' : '4px 16px', // Adjust padding for small screens
                }}
              >
                <SortSection
                  results={filteredResults}
                  setFilteredResults={setFilteredResults}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  type={'provider'}
                />
              </Box>
            </Box>
            <ResultsSection searchResults={pagifiedResults} type={'provider'} cacheId={cacheId} />
          </Paper>
        </Grid>

        {/* Third Column (Other Content) */}
        <Grid item xs={12} sm={4} md={3}>
          <Paper sx={{ borderRadius: 2 }}>
            <TextCard type={'SeekBusinessReviews'} />
          </Paper>
          <Paper>
            <Footer />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessNameSerachResults;

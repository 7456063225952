import React, {useState, useEffect} from 'react';
import { Box, Grid, Paper, Avatar, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAddTrustedUserResMutation  } from '../../redux/thunks/users/useraddtrusteduserres';
import { useGetUserIncomingReqQuery } from '../../redux/thunks/users/userincomingrequests';



const PendingInvites = () => {

  const navigate = useNavigate();

  const userId = useSelector((state) => state.auth.userId)  
  const [addTrustedUserRes, { isLoading }] = useAddTrustedUserResMutation();
  const [invites, setInvites] = useState([]); // State to hold the search query
  const { data: incoming, isLoading: incomingLoading, isSuccess: incomingSuccess  } = useGetUserIncomingReqQuery(userId);

  useEffect(() => {
    if (incomingSuccess) {
      console.log('incoming', incoming)
      // Combine two arrays and create a new array with only unique values.
      const incomingIds = incoming.pendingInTrustedUsers.map(item => item.trustedUserId);    
      //const pendingInvites = incoming.pendingInTrustedUsers ;   
      const pendingInvites = incoming.pendingInTrustedUsers.filter((invite) => invite.state === 'notification' ) ;   
      setInvites(pendingInvites)
      
      // Create a new array with only unique ids.
      const profileIds = [...new Set([...incomingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [incoming]);


  // Handler function for accepting an invitation
  const handleAcceptInvite =  async (invite) => {
    const payload = { 'trustedUserId': invite.trustedUserId, response: 1, 'userId': userId }
    await addTrustedUserRes(payload);
    setInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Accepted invitation from ${invite.displayName}`);
  };

  // Handler function for ignoring an invitation
  const handleIgnoreInvite = (invite) => {
    // Implement your logic here to ignore the invitation
    console.log(`Ignored invitation from ${invite.displayName}`);
  };
  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };

  console.log('invites', invites)

  return (
    <Grid container spacing={2}>
        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:'100%' }}>
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          Invitations
        </Typography>
        
        <Typography variant="subtitle2" sx={{ mr: 0 }}>
          See all
        </Typography>
          
        
      </Box>
      {invites.map((invite) => (
        <Grid item xs={12} key={invite.trustedUserId}>
          <Paper variant="outlined" sx={{ padding: '5px', display: 'flex', alignItems: 'center',  }}>
            {/* First Column: User's Picture */}
            <UserPhoto photoUrl={invite.profileImageUrl}  name={invite.displayName} size={50} />

            {/* Second Column: Display Name */}
            <Box sx={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box>
                    <Typography variant="subtitle2" style={{ cursor: 'pointer' }} onClick={() => handleNavigation2Profile(invite.trustedUserId)}>
                     {invite.displayName}
                     </Typography>

                </Box>

                <Box>
                    {/* Accept and Ignore Buttons */}
                    <Button variant="outlined" 
                        color="primary" 
                        size="small" 
                        sx={{ marginRight: '4px' }}
                        onClick={() => handleAcceptInvite(invite)} 
                        disabled={isLoading}
                    >
                        Accept
                    </Button>
                    <Button 
                        variant="text" 
                        color="gray" 
                        size="small"
                        onClick={() => handleIgnoreInvite(invite)} 
                        disabled={isLoading}
                    >
                        Reject
                    </Button>
                    
                </Box>              
            </Box>
          </Paper>

          {/* Message Box */}
          {invite.message && (
            <Paper variant="outlined" sx={{ padding: '10px', marginTop: '10px' }}>
              <Typography variant="body2">{invite.message}</Typography>
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PendingInvites;

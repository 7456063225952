import React, { useState, useEffect, useCallback } from 'react';
import UserPhoto from '../../../Components/UserPhoto/UserPhoto';
import { Chip, Box, LinearProgress, Typography, Tooltip, Stack, Divider } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import { usePrefetch as useGetSpProfilePrefetch } from '../../../redux/thunks/sp/getspprofile';
import { usePrefetch as useGetSpReviewPrefetch } from '../../../redux/thunks/sp/spreviewget';

const StarRating = ({ rating }) => {
  return (
    <Rating
      name="custom-rating"
      value={rating} // Use the value prop to set the rating value
      precision={0.5}
      readOnly
    />
  );
};

const Provider = ({ result, cacheId }) => {
  const navigate = useNavigate();
  const totalReviews = 111;
  const minimumPercentage = 1;
  const token = useSelector((state) => state.auth.token);

  const prefetchGetSpProfile = useGetSpProfilePrefetch('getSpProfile');
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview');

  let initials = result.displayName ? `${result.displayName.split(' ')[0][0].toUpperCase()}${result.displayName.split(' ')[1][0].toUpperCase()}` : "RV";
  const businessName = result.businessName.replace(/\b\w/g, (char) => char.toUpperCase());

  const args = {
    spId: result.spId,
    authType: 'token',
    token: token,
  };
  const revArgs = {
    spId: result.spId || '123456789',
    authType: 'token',
    token: token,
    businessId: result._id
  };

  const handleNavigation2Profile = (id) => {
    navigate(`/provider/profile/${id}`, { state: { businessId: result._id, businessName: result.businessName, skill: result, cacheId } });
  };

  const calculateProgressValues = useCallback(() => {
    let totalReviews = result.count_all;
    let connectedReviews = result.count_all - result.count_hops[0] || 0;
    let notConnectedReviews = result.count_hops[0] || 0;
    let anonymousReviews = result.count_none;    

    const greenPercentage = Math.max((connectedReviews / totalReviews) * 100, minimumPercentage);
    const yellowPercentage = Math.max(((notConnectedReviews) / totalReviews) * 100, minimumPercentage);
    const redPercentage = Math.max((anonymousReviews / totalReviews) * 100, minimumPercentage);

    return {
      redPercentage,
      greenPercentage,
      yellowPercentage,
      totalReviews,
    };
  }, [result]);

  const [progressValues, setProgressValues] = useState(calculateProgressValues());

  useEffect(() => { 
    const prefetchAll = async () => {    
      await Promise.all([prefetchGetSpProfile(args), prefetchGetSpReview(revArgs)]);
    };    
    prefetchAll();
  }, [result]);

  useEffect(() => {
    const calculatedProgressValues = calculateProgressValues();
    setProgressValues(calculatedProgressValues);
  }, [result, calculateProgressValues]);

  const [ratingValue, setRatingValue] = useState(result.avgSkillRating_all);

  return (
    <Box sx={{ width: '100%', height: 'auto', bgcolor: 'white', borderRadius: 2, p: 2, my: 0 }}>
      <Box sx={{ display: 'flex', height: 'auto', flexDirection: { xs: 'column', sm: 'row' } }}>
        {/* First Box (User Photo & Info) */}
        <Box sx={{ flex: { xs: 'none', sm: '10%' }, p: 1, display: 'flex', justifyContent: 'center' }}>
          <UserPhoto photoUrl={result.photoUrl} name={businessName} size={80} />
        </Box>

        {/* Second Box (Business Name, Rating, Chips, etc.) */}
        <Box sx={{ flex: { xs: 'none', sm: '40%' }, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: { xs: 'center', sm: 'flex-start' } }}>
          <Typography variant="h6" fontWeight="bold" mb={1} sx={{cursor: 'pointer'}} onClick={() => handleNavigation2Profile(result.spId)}>
            {businessName}
          </Typography>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UserPhoto photoUrl={result.spPhoto} name={result.spDisplayName} size={16} fontSize={9} />
              <Typography variant="body2" fontWeight="bold" ml={1} onClick={() => handleNavigation2Profile(result.spId)}>
                {result.spDisplayName}
              </Typography>
            </Box>
            <Typography variant="body2" fontWeight="bold" mb={1} onClick={() => handleNavigation2Profile(result.spId)}>
              {result.skillName}
            </Typography>
          </Stack>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: -2, justifyContent: 'center' }}>
            <Chip
              label={result.authorizedPersonal ? 'Claimed' : 'Unclaimed'}
              color={result.authorizedPersonal ? 'success' : 'default'}
              sx={{ mr: 1 }}
              size="small"
            />
            {result.authorizedPersonal &&
              <Chip
                label={result.verified ? 'Verified' : 'Unverified'}
                color={result.verified ? 'success' : 'default'}
                sx={{ mr: 1 }}
                size="small"
              />
            }
            <Box sx={{ ml: 2, mb: 2 }}>
              <Typography variant="caption" fontWeight="bold" mb={1}>
                {result.region || 'Unknown'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, justifyContent: 'center' }}>
            <StarRating rating={ratingValue} />
            <Typography variant="subtitle2" sx={{ mt: '5px', mr: 1 }}>
              {Math.round(result.avgSkillRating_all * 10) / 10} ({progressValues.totalReviews} Reviews)
            </Typography>
          </Box>
        </Box>

        {/* Third Box (Progress Values & Rating) */}
        <Box sx={{ flex: { xs: 'none', sm: '10%' }, p: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box display={'flex'} flexDirection="column" alignItems="center">
            <Typography variant="subtitle2" sx={{ mb: 0 }}>
              References
            </Typography>
            <Box sx={{ width: 50, height: 50, ml: 1 }}>
              <CircularProgressbar
                value={progressValues.greenPercentage}
                text={`${Math.floor(progressValues.greenPercentage)}%`}
                styles={buildStyles({
                  textSize: "34px",
                  pathColor: 'blue',
                })}
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <Typography variant="subtitle2" sx={{ mt: 0, mr: 1 }}>
                Smart Rating
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
              <StarRating rating={ratingValue} />
              <Typography variant="subtitle2" sx={{ mt: '0px', mr: 1 }}>
                {Math.round(result.SkillValue_all * 10) / 10}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Provider;

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Paper, Chip, CircularProgress } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Utils from '../../Functions/Utilities';

import { useGetSpProfileQuery } from '../../redux/thunks/sp/getspprofile';
import { usePrefetch as useGetSpReviewPrefetch } from '../../redux/thunks/sp/spreviewget';
import { usePrefetch as useUserTrustChainPrefetch } from '../../redux/thunks/users/usertrustchainsget';
import { useGetUserProfileQuery } from '../../redux/thunks/users/userprofile';
//import MostReviewedBusinesses from './MostReviewedBusinesses';

import SpFacePlate from './SpFacePlate';
import UserEndorsements from './UserEndorsements';
import CommonFromNetwork from './CommonFromNetwork';

import SkillFacePlate from './SkillFacePlate';
import ShowReviews from './ShowReviews';
import SkillStats from './SkillStats';
import SimilarProviders from './SimilarProviders';

import SearchComponent from '../../Components/SearchComponent/SearchComponent';
import { useSpFindMutation } from '../../redux/thunks/sp/spfind';

import { useGetUserCacheDataQuery } from '../../redux/thunks/users/usercachedata';
import GenerateAnswers from './GenerateAnswers'; // Import the new component
import NoBusinessPrompt from './NoBusinessPrompt';

const ProviderProfile = () => {
  const location = useLocation();
  console.log('in provider profile, location.state', location.state);

  const skill = location.state.skill;
  const businessId = location.state.businessId;
  const businessName = location.state.businessName;
  const cacheId = location.state?.cacheId ?? null;

  console.log('in provider profile, skill', skill);
  console.log('in provider profile, businessId', businessId);
  console.log('in provider profile, businessName', businessName);

  //console.log('commonFromMyNetwork', commonFromMyNetwork)

  const { spId } = useParams();

  const [ready, setReady] = useState(true);
  const [isMyProfile, setIsMyProfile] = useState(false);
  const [isSpSystem, setIsSpSystem] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState(businessId);
  const [selectedBusinessName, setSelectedBusinessName] =
    useState(businessName);
  const [selectedSkill, setSelectedSkill] = useState(skill);
  const [profileData, setProfileData] = useState('');

  const navigate = useNavigate();
  console.log('in provider profile, spId', spId);
  const token = useSelector((state) => state.auth.token);
  const thisSpId = useSelector((state) => state.auth.user.spId);

  const args = {
    spId: spId,
    authType: 'token',
    token: token,
    businessId: businessId,
  };
  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = useGetSpProfileQuery(args);
  //const { data: reviews, error: reviewError, isLoading: reviewLoading } = useGetSpReviewQuery(args);
  const prefetchGetSpReview = useGetSpReviewPrefetch('getSpReview');
  const prefetchUserTrustChain = useUserTrustChainPrefetch('getUserTrustchain');

  useEffect(() => {
    console.log('outside profile useEffect hook, profile', profile);

    if (profile && profile.email === 'admin@revfer.com') {
      setProfileData(profile);
      setReady(false);
      setIsSpSystem(true);
    }

    if (profile && profile.email !== 'admin@revfer.com') {
      console.log('inside profile useEffect hook');
      let me = true;
      if (thisSpId !== spId) {
        prefetchUserTrustChain(profile.userId);
        me = false;
      }
      setIsMyProfile(me);

      if (
        businessId === 'default' &&
        businessName === 'default' &&
        profile.businesses.length > 0
      ) {
        setSelectedBusinessName(profile.businesses[0].businessName);
        setSelectedBusinessId(profile.businesses[0]._id);
        setSelectedSkill(profile.businesses[0]);
      }
      //todo: change the below to select skill based on businessId
      if (businessId !== 'default') {
        setSelectedSkill(profile.businesses[0]);
        setSelectedBusinessName(businessName);
        setSelectedBusinessId(businessId);
      }
      if (profile.businesses.length > 0) {
        profile.businesses.forEach((element) => {
          const revArgs = {
            spId: spId,
            authType: 'token',
            token: token,
            businessId: element._id,
          };
          prefetchGetSpReview(revArgs);
        });
      }
      setProfileData(profile);
      setReady(false);
    }
  }, [
    profile,
    spId,
    prefetchUserTrustChain,
    thisSpId,
    businessId,
    businessName,
    location.state,
  ]);

  const isLoading = profileLoading || ready;
  const error = profileError;

  console.log('profile', profile);

  //to add 'All' tag use the folowing commented line

  //return ['All', ...profile?.skills].map((skill, index) => {

  const renderTags = () => {
    console.log('in render tags');
    return profile?.businesses.map((business, index) => {
      const isSelected = selectedBusinessName.includes(business.businessName);

      return (
        <Chip
          key={business._id}
          sx={{
            mr: 1,
            mb: 2,
            cursor: 'pointer',
            backgroundColor: isSelected ? 'primary.main' : 'default',
            color: isSelected ? 'white' : 'text.primary',
          }}
          onClick={() => {
            setSelectedBusinessName(business.businessName);
            setSelectedBusinessId(business._id);
          }}
          label={business.businessName}
        />
      );
    });
  };

  if (isLoading) {
    // Show a loading spinner if either user data or activities data is loading
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={'90vh'}
        sx={{ bgcolor: '#FFFFFF', width: '100%' }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  // Render NoBusinessPrompt if profileData or profileData.businesses is empty
  if (profileData.businesses.length === 0 && businessId === 'default') {
    return <NoBusinessPrompt />;
  }

  return (
    <Box maxWidth={1280} mx="auto" p={2}>
      {/* First Full-Width Row with Border */}
      <Paper
        variant="outlined"
        style={{ marginBottom: '0px', padding: '0px', border: 'none' }}
      ></Paper>

      {/* Three Columns: 25%, 50%, 25% */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          {/* First Column (25%) */}
          <Paper
            variant="outlined"
            sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
          >
            {profileData && (
              <SpFacePlate
                sp={profileData}
                skill={profileData.businesses.find(
                  (element) => element.businessName === selectedBusinessName
                )}
              />
            )}
          </Paper>

          {selectedBusinessName !== 'All' && !isSpSystem && (
            <Paper
              variant="outlined"
              sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
            >
              <CommonFromNetwork
                skill={profileData.businesses.find(
                  (element) => element.businessName === selectedBusinessName
                )}
              />
            </Paper>
          )}

          {selectedBusinessName !== 'All' && !isSpSystem && (
            <Paper
              variant="outlined"
              sx={{ padding: '0px', border: 'none', mb: 1, bgcolor: '#F8F9F9' }}
            >
              <SimilarProviders
                data={profileData.businesses.find(
                  (element) => element.businessName === selectedBusinessName
                )}
                spId={spId}
                isMyProfile={isMyProfile}
              />
            </Paper>
          )}

          {selectedBusinessName !== 'All' && isSpSystem && (
            <Paper
              variant="outlined"
              sx={{
                padding: '0px',
                border: 'none',
                mb: 1,
                borderRadius: 2,
                bgcolor: '#F8F9F9',
              }}
            >
              <SimilarProviders
                data={selectedSkill}
                spId={spId}
                isMyProfile={isMyProfile}
              />
            </Paper>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Second Column (50%) */}

          {!isSpSystem && profileData.businesses.length > 1 && (
            <Box
              sx={{
                padding: '15px',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                mb: 4,
                mt: 0,
                borderRadius: '10px',
              }}
            >
              <Paper
                variant="outlined"
                sx={{ border: 'none', bgcolor: '#F8F9F9' }}
              >
                {renderTags()}
              </Paper>
            </Box>
          )}

          {/* Second row in the second column */}

          {selectedBusinessName !== 'All' && !isSpSystem && (
            <Paper
              variant="outlined"
              sx={{  border: 'none', mb: 4, borderRadius: 2 }}
            >
              <SkillFacePlate
                skill={profileData.businesses.find(
                  (element) => element.businessName === selectedBusinessName
                )}
                spId={spId}
                isMyProfile={isMyProfile}
              />
            </Paper>
          )}

          {selectedBusinessName !== 'All' && isSpSystem && (
            <Paper
              variant="outlined"
              sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
            >
              <SkillFacePlate
                skill={selectedSkill}
                spId={spId}
                isMyProfile={isMyProfile}
              />
            </Paper>
          )}

          <Paper
            variant="outlined"
            sx={{ border: 'none', mb: 4, borderRadius: 2 }}
          >
            <GenerateAnswers spId={spId} businessId={selectedBusinessId} />
          </Paper>

          <Paper variant="outlined" sx={{ border: 'none', bgcolor: '#F8F9F9' }}>
            <ShowReviews spId={spId} businessId={selectedBusinessId} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* Third Column (25%) */}
          {selectedBusinessName !== 'All' && !isSpSystem && (
            <Paper
              variant="outlined"
              sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
            >
              <SkillStats
                skill={profileData.businesses.find(
                  (element) => element.businessName === selectedBusinessName
                )}
                spId={spId}
              />
            </Paper>
          )}
          {selectedBusinessName !== 'All' && isSpSystem && (
            <Paper
              variant="outlined"
              sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
            >
              <SkillStats skill={selectedSkill} spId={spId} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProviderProfile;

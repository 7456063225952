import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material'; // Import Material-UI components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Accordion expand icon
import Lists from './Lists/Lists'; // Import the existing Lists component

const iconName = 'Notes'; // The name of the MUI icon
const heading = 'Top Categories';

const TopCategories = ({ categories }) => {
  const theme = useTheme(); // Get the current theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is 'sm' or smaller

  const names = categories.map((category) => ({
    businessId: category._id,
    businessName: category.name,
  }));

  if (isMobile) {
    // Render as an accordion for mobile screens
    return (
      <Accordion
        sx={{
          borderRadius: 0, // Remove rounded corners
          border: 'none', // Remove border
          boxShadow: 'none', // Remove box-shadow
        }}
      >
        {/* Accordion Summary */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="top-categories-content"
          id="top-categories-header"
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '1rem', // Match "Most Reviewed" font size
            }}
          >
            {heading}
          </Typography>
        </AccordionSummary>

        {/* Accordion Details */}
        <AccordionDetails
          sx={{
            padding: '8px 16px',
          }}
        >
          <Box>
            <Lists
              heading={heading}
              names={names}
              iconName={iconName}
              rowSpacing={-2}
              iconSpacing={-3}
              isBusiness={false}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

  // Render as a simple list for larger screens
  return (
    <Box>
      <Lists
        heading={heading}
        names={names}
        iconName={iconName}
        rowSpacing={-2}
        iconSpacing={-3}
        isBusiness={false}
      />
    </Box>
  );
};

export default TopCategories;

import React, { useState, useEffect } from 'react';
import { TextField, Button, CircularProgress, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useFormik } from 'formik'; // Import useFormik
import * as yup from 'yup'; // Import Yup for form validation

import { useFindUserMutation } from '../../redux/thunks/users/userfind';

const SearchBox = () => {
  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query
  const navigate = useNavigate(); // For navigation

  const [triggerSearch, { data, isLoading }] = useFindUserMutation();

  useEffect(() => {
    if (data) {
      navigate(`/search/users/results`, {
        state: { query: searchQuery, searchResults: data },
      });
    }
  }, [data, navigate]);

  // Define form validation schema using Yup
  const validationSchema = yup.object({
    searchQuery: yup.string().required('Please enter a search query'),
  });

  // Use useFormik to handle form state and validation
  const formik = useFormik({
    initialValues: {
      searchQuery: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Execute the mutation to find users based on the search query
        const payload = { text: values.searchQuery };
        await triggerSearch({ payload });
        setSearchQuery(values.searchQuery);
      } catch (error) {
        console.error('Error searching for users:', error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        position="relative"
        spacing={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={8}>
          <TextField
            label="Enter name or email address"
            variant="outlined"
            fullWidth
            id="searchQuery"
            name="searchQuery"
            value={formik.values.searchQuery}
            onChange={formik.handleChange}
            error={
              formik.touched.searchQuery && Boolean(formik.errors.searchQuery)
            }
            helperText={formik.touched.searchQuery && formik.errors.searchQuery}
            sx={{
              backgroundColor: '#f5f5f5', // Change the background color as needed
              // height: "32px", // Adjust the height as needed
              '& .MuiInputBase-input': {
                paddingBottom: '12px',
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={isLoading || !formik.isValid}
            sx={{
              paddingX: 10,
              width: 60,
              position: 'absolute',
              top: 20,
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ marginLeft: 2 }} />
            ) : (
              'Search'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBox;

import React from 'react';
import { Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lists from './Lists/Lists';

const iconName = 'Notes'; // The name of the MUI icon
const heading = 'Most Reviewed';

const MostReviewedBusinesses = ({ businesses }) => {
  console.log('Most reviewed businesses: ', businesses);

  if (!businesses) {
    return null;
  }

  const names = businesses.map((business) => ({
    businessId: business.businessId ? business.businessId : business._id,
    businessName: business.businessName,
  }));

  console.log('names in Most reviewed businesses', names);

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: 0,
        border: 'none', // Remove the border
        boxShadow: 'none', // Remove the box-shadow
      }}
    >
      <Box sx={{ display: { xs: 'block', md: 'none' } }}> {/* Accordion only for mobile */}
        <Accordion
          sx={{
            boxShadow: 'none', // Remove box-shadow
            border: 'none', // Remove border
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {heading}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Lists
              heading={heading}
              names={names}
              iconName={iconName}
              rowSpacing={-2}
              iconSpacing={-3}
              isBusiness={true}
            />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'block' } }}> {/* Normal list for larger screens */}
        <Lists
          heading={heading}
          names={names}
          iconName={iconName}
          rowSpacing={-2}
          iconSpacing={-3}
          isBusiness={true}
        />
      </Box>
    </Paper>
  );
};

export default MostReviewedBusinesses;

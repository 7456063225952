import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Paper, Typography } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import NotificationList from './NotificationList';

import PhotoCard from '../../Components/ByteSize/PhotoCards/PhotoCard';
import Footer from '../../Components/Footer/Footer';

const Notifications = () => {
  return (
    <Box
      backgroundColor="#f5f5f5"
      maxWidth={1280}
      minHeight={'90vh'}
      mx="auto"
      p={2}
    >
      {/* First Full-Width Row */}

      {/* Three Columns Row */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Paper
            variant="outlined"
            sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
          >
            {/* Content for the first column */}
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ cursor: 'pointer' }}
            >
              Leran more about Revfer
            </Typography>
            <Link
              to={'https://www.revfer.com'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Help Center
              </Typography>
            </Link>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
          >
            {/* You can add your content here */}
            <NotificationList />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper
            variant="outlined"
            sx={{ padding: '10px', border: 'none', mb: 1, borderRadius: 2 }}
          >
            {/* Content for the third column */}
            <PhotoCard type={'NeedReview'} />
          </Paper>

          {/* <Paper variant="outlined" sx={{ padding: '10px', border: 'none', mb:1, borderRadius: 2, bgcolor: '#F8F9F9' }}>
            <Footer/>
          </Paper> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notifications;

import React from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import { Typography } from '@mui/material';

const FullscreenLoader = ({ message }) => {
  const loaderStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column', 
    textAlign: 'center',
    height: '80vh',
    width: '100%', 
  };

  return (
    <div style={loaderStyles}>
      <ClockLoader size={160} color={'#1a76e8'} loading={true} />
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'center', 
          marginTop: 3, 
          color: "#000" 
        }}
      >
        {message}
      </Typography>
    </div>
  );
};

export default FullscreenLoader;

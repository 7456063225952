import React, {useState, useEffect} from 'react';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import UserPhoto from '../../Components/UserPhoto/UserPhoto';
import { useNavigate } from 'react-router-dom';


import { useGetUserOutgoingReqQuery } from '../../redux/thunks/users/useroutgoingrequests';
import { usePrefetch } from '../../redux/thunks/users/userprofile';
import { useAddTrustedUserMutation  } from '../../redux/thunks/users/useraddtrusteduser';




const SentInvites = () => {

  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.userId)
  
  const [invites, setInvites] = useState([]); // State to hold the search query
  const { data: outgoing, isLoading: outgoingLoading, isSuccess: outgoingSuccess  } = useGetUserOutgoingReqQuery(userId);
  const [addTrustedUser, { isLoading }] = useAddTrustedUserMutation();


  useEffect(() => {
    if (outgoingSuccess) {
      // Combine two arrays and create a new array with only unique values.
      
      const outgoingIds = outgoing.pendingOutTrustedUsers.map(item => item.trustedUserId);
      const pendingOutvites = outgoing.pendingOutTrustedUsers;   

      setInvites(pendingOutvites)

      
      // Create a new array with only unique ids.
      const profileIds = [...new Set([ ...outgoingIds])];
      //profileIds.forEach(id => prefetch(id));
    }
  }, [outgoing]);

  console.log('invites', invites)

  // Handler function for accepting an invitation
  const handleRemindInvite =  async (invite) => {
    const payload = { 'trustedUserId': invite.trustedUserId, requestType: 'reminder'  }
    await addTrustedUser({payload, userId: invite.trustedUserId});
    
    setInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Reminder sent from ${invite.displayName}`);
  };

  // Handler function for ignoring an invitation
  const handleWithdrawInvite = async (invite) => {
    // Implement your logic here to ignore the invitation
    const payload = { 'trustedUserId': invite.trustedUserId, requestType: 'withdraw'  }
    await addTrustedUser({payload, userId: invite.trustedUserId});
    
    setInvites((prevInvites) => prevInvites.filter((prevInvite) => prevInvite.trustedUserId !== invite.trustedUserId));

    console.log(`Withdrown invitation from ${invite.displayName}`);
  };

  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };

  return (
    <Grid container spacing={2}>
        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:'100%' }}>
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          Invitations (Sent)
        </Typography>
        
        <Typography variant="subtitle2" sx={{ mr: 0 }}>
          See all
        </Typography>
          
        
      </Box>
      {invites.map((invite) => (
        <Grid item xs={12} key={invite.trustedUserId}>
          <Paper variant="outlined" sx={{ padding: '5px', display: 'flex', alignItems: 'center',  }}>
            {/* First Column: User's Picture */}
            <UserPhoto photoUrl={invite.profileImageUrl}  name={invite.displayName} size={50} />

            {/* Second Column: Display Name */}
            <Box sx={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box>
                    <Typography variant="subtitle2" sx={{cursor: 'pointer' }} onClick={() => handleNavigation2Profile(invite.trustedUserId)}>
                     {invite.displayName}
                     </Typography>

                </Box>

                <Box>
                    {/* Accept and Ignore Buttons */}
                    <Button variant="outlined" 
                        color="primary" 
                        size="small" 
                        sx={{ marginRight: '4px' }}
                        onClick={() => handleRemindInvite(invite)} 
                        disabled={isLoading}
                    >
                        Remind
                    </Button>
                    <Button 
                        variant="text" 
                        color="gray" 
                        size="small"
                        onClick={() => handleWithdrawInvite(invite)} 
                        disabled={isLoading}
                    >
                        Withdraw
                    </Button>
                    
                </Box>              
            </Box>
          </Paper>

          {/* Message Box */}
          {invite.message && (
            <Paper variant="outlined" sx={{ padding: '10px', marginTop: '10px' }}>
              <Typography variant="body2">{invite.message}</Typography>
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default SentInvites;

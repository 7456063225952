// GenerateAnswers.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useGenerateAnswersMutation } from "../../redux/thunks/ai/generateanswers";

const GenerateAnswers = ({ businessId }) => {
  const token = useSelector((state) => state.auth.token);
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [error, setError] = useState("");

  const [generateAnswer, { isLoading, isError, data }] =
    useGenerateAnswersMutation();

  const handleGenerate = async () => {
    if (query.trim() === "") {
      setError("This field is required.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    try {
      const auth = {
        token: token,
        authType: "token",
      };
      const payload = {
        auth,
        businessId,
        query,
      };

      console.log("API Payload:", payload);
      const response = await generateAnswer({ payload }).unwrap();
      console.log("response", response);
      setResult(response.text);
      setQuery("");
    } catch (error) {
      console.error("Error generating answer:", error);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Ask a question about this business
      </Typography>
      <TextField
        fullWidth
        label="Your Question"
        variant="outlined"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        error={!!error}
        helperText={error}
        sx={{ mb: 2 }}
      />
      {/* {error} */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGenerate}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : "Generate Answer"}
      </Button>
      {result && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <strong>Answer:</strong> {result}
          </Typography>
        </Box>
      )}
      {isError && (
        <Typography variant="body2" color="error">
          An error occurred while generating the answer.
        </Typography>
      )}
    </Box>
  );
};

export default GenerateAnswers;

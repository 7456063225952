import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import SmsIcon from "@mui/icons-material/Sms";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BusinessIcon from "@mui/icons-material/Business";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../redux/slices/auth";

const Header = ({ onSearch }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { authStatus, user, signOut: authSignOut } = useAuthenticator(
    (context) => [context.authStatus, context.user]
  );

  const { isLoggedIn, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authStatus === "unauthenticated") {
      //todo: create a landing page link here
      //navigate(`/auth/signin`);
    }
  }, [authStatus, navigate]);

  const onLogOut = () => {
    authSignOut();
    dispatch(signOut());
    navigate(`/auth/signout`);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavItemClick = () => {
    setMobileOpen(false); // Close the drawer after a nav item is clicked
  };

  const navLinks = [
    { label: "Home", to: "/home", icon: <HomeIcon /> },
    { label: "My Network", to: "/mynetwork", icon: <PeopleIcon /> },
    { label: "Chat", to: "/chat", icon: <SmsIcon /> },
    { label: "Notifications", to: "/notifications", icon: <NotificationsActiveIcon /> },
    { label: "For Business", to: "/landing/business", icon: <BusinessIcon /> },
  ];

  const drawerContent = (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 2,
          py: 1,
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src="/assets/revfer/skyblue.svg"
            alt="Revfer Logo"
            style={{ width: "100px", height: "50px" }}
          />
        </Link>
        <IconButton
          sx={{
            color: "black",
          }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        {navLinks.map((link, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={link.to}
              onClick={handleNavItemClick}
              sx={{
                gap: 1, // Added space between icon and text
              }}
            >
              {link.icon}
              <ListItemText primary={link.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              onLogOut();
              handleNavItemClick();
            }}
            sx={{
              gap: 1, // Added space between icon and text
            }}
          >
            <ExitToAppIcon />
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const loggedInHeader = (
    <Box display="flex">
      {navLinks.map((link, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mr: 5,
          }}
        >
          <IconButton
            component={Link}
            to={link.to}
            color="inherit"
            style={{ textDecoration: "none", color: "black" }}
          >
            {link.icon}
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            color="black"
            sx={{ marginTop: "-5px" }}
          >
            {link.label}
          </Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mr: 5,
        }}
      >
        <IconButton
          color="inherit"
          onClick={onLogOut}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ExitToAppIcon />
        </IconButton>
        <Typography variant="caption" align="center" color="black" sx={{ marginTop: "-5px" }}>
          Sign Out
        </Typography>
      </Box>
    </Box>
  );

  const loggedOutHeader = (
    <Stack direction="row" spacing={2}>
      <Button
        component={Link}
        to="/auth/signin"
        style={{ textDecoration: "none", color: "black" }}
      >
        For Business
      </Button>
      <Button
        variant="outlined"
        component={Link}
        to="/auth/signin"
        style={{ textDecoration: "none", color: "blue" }}
      >
        Sign In
      </Button>
      <Button
        variant="contained"
        disableElevation
        component={Link}
        to="/auth/signin"
        style={{ textDecoration: "none", color: "white" }}
      >
        Join
      </Button>
    </Stack>
  );

  if (loading) {
    return null;
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Added boxShadow
          mb: 5,
          width: "100%",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to="/" style={{ overflow: "hidden" }}>
            <img
              src="/assets/revfer/skyblue.svg"
              alt="Revfer Logo"
              style={{ width: "100px", height: "50px" }}
            />
          </Link>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {authStatus === "authenticated" ? loggedInHeader : loggedOutHeader}
          </Box>
          <IconButton
            color="black"
            sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "center" }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default Header;

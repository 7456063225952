import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Stack,
  Paper,
  Autocomplete,
  Box,
  IconButton,
} from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useEditBusinessMutation } from '../../redux/thunks/sp/editbusiness';
import { useGetAllThingsQuery } from '../../redux/thunks/things/thingsget';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { spApi } from '../../redux/thunks/sp/sp';
import { useNavigate } from 'react-router-dom';
import SelectPhotoPage from '../../Components/FilesUpload/SelectPhotoPage';

const EditBusiness = ({ isOpen, onClose, businessDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState(businessDetails.name);
  const [category, setCategory] = useState('');
  const [dcategory, setDcategory] = useState(businessDetails.skillName || '');
  const [subCategories, setSubCategories] = useState([]);
  const [address, setAddress] = useState(businessDetails.address);
  const [findMeAt, setFindMeAt] = useState(businessDetails.platforms || []);
  const [catData, setCatData] = useState([]);
  const [postEdit, { isLoading }] = useEditBusinessMutation();

  const token = useSelector((state) => state.auth.token);

  const args = {
    spId: businessDetails.spId,
    authType: 'token',
    token: token,
  };
  const { data: categoriesData, isLoading: iscategoriesDataLoading } =
    useGetAllThingsQuery(args);
  console.log('categoriesData ', categoriesData);
  console.log('businessDetails ', businessDetails);
  console.log('findMeAt', findMeAt);

  useEffect(() => {
    setBusinessName(businessDetails.name);
    setCategory('');
    setSubCategories([]);
    setDcategory(businessDetails.skillName);
    setFindMeAt(businessDetails.platforms || []);
  }, [businessDetails]);

  useEffect(() => {
    if (categoriesData && categoriesData.length) {
      setCatData(categoriesData);
    }
  }, [categoriesData]);

  const handleAddPlatform = () => {
    setFindMeAt([...findMeAt, { name: '', link: '' }]);
  };

  const handleDeletePlatform = (index) => {
    const updatedFindMeAt = [...findMeAt];
    updatedFindMeAt.splice(index, 1);
    setFindMeAt(updatedFindMeAt);
  };

  const handlePlatformNameChange = (index, value) => {
    const updatedFindMeAt = [...findMeAt];
    updatedFindMeAt[index].name = value;
    setFindMeAt(updatedFindMeAt);
  };

  const handlePlatformLinkChange = (index, value) => {
    const updatedFindMeAt = [...findMeAt];
    updatedFindMeAt[index].link = value;
    setFindMeAt(updatedFindMeAt);
  };

  const handleSubmit = async () => {
    console.log('values while submitting: businessName ', businessName);
    console.log('values while submitting: category ', category);
    console.log('values while submitting: dcategory ', dcategory);
    console.log('values while submitting: subCategories ', subCategories);
    console.log('values while submitting: address ', address);
    console.log('values while submitting: findMeAt ', findMeAt);

    const auth = {
      token: token,
      authType: 'token',
    };
    const data = {
      socialLinks: findMeAt,
      address: address,
      businessName: businessName,
    };

    const payload = {
      auth,
      skillId: category.skillId,
      otherSkillIds: subCategories.map((obj) => obj.skillId),
      spId: businessDetails.spId,
      data: data,
      businessId: businessDetails._id,
    };

    await postEdit({ payload });
    dispatch(
      spApi.util.invalidateTags([
        { type: 'Spprofile', id: businessDetails.spId },
      ])
    );
    navigate(`/provider/profile/${businessDetails.spId}`, {
      state: {
        businessId: businessDetails._id,
        businessName: businessName,
        skill: 'default',
      },
    });
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ width: '100%', height: '100%' }}
    >
      <DialogTitle>
        <Typography variant="h6">Edit Business</Typography>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={0} sx={{ padding: 3 }}>
          <Stack spacing={3}>
            <TextField
              label="Business Name"
              variant="outlined"
              fullWidth
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
            />
            <Autocomplete
              options={catData.data}
              getOptionLabel={(option) => (option ? option.name || '' : '')}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Select category'}
                  variant="outlined"
                />
              )}
              value={category}
              onChange={(_, newValue) => {
                setCategory(newValue);
                setDcategory(newValue ? newValue.name : newValue);
              }}
              sx={{ '& .MuiAutocomplete-inputRoot': { paddingTop: 1 } }}
              loading={iscategoriesDataLoading}
            />
            <Autocomplete
              multiple
              options={catData.data}
              getOptionLabel={(option) => (option ? option.name || '' : '')}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Subcategories"
                  variant="outlined"
                />
              )}
              value={subCategories}
              onChange={(_, newValues) => setSubCategories(newValues)}
              sx={{ '& .MuiAutocomplete-inputRoot': { paddingTop: 1 } }}
              loading={iscategoriesDataLoading}
            />
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={async (selectedAddress) => {
                setAddress(selectedAddress);
              }}
              searchOptions={{
                types: ['address'],
              }}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                  <TextField
                    label="Address"
                    variant="outlined"
                    fullWidth
                    {...getInputProps()}
                    sx={{ '& .MuiInput-input': { paddingTop: 0 } }}
                  />
                  <Box
                    className="suggestions"
                    sx={{
                      backgroundColor: '#fff',
                      maxHeight: '150px',
                      overflowY: 'auto',
                    }}
                  >
                    {suggestions.map((suggestion, index) => (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion)}
                        className="suggestion-item"
                        sx={{ padding: 2, cursor: 'pointer' }}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </Box>
                </div>
              )}
            </PlacesAutocomplete>
            <Typography variant="h6">Find Me At</Typography>
            {findMeAt.map((platform, index) => (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <TextField
                  label="Platform Name"
                  variant="outlined"
                  fullWidth
                  value={platform.name}
                  onChange={(e) =>
                    handlePlatformNameChange(index, e.target.value)
                  }
                />
                <TextField
                  label="Platform Link"
                  variant="outlined"
                  fullWidth
                  value={platform.link}
                  onChange={(e) =>
                    handlePlatformLinkChange(index, e.target.value)
                  }
                />
                <IconButton
                  onClick={() => handleDeletePlatform(index)}
                  color="secondary"
                  aria-label="Delete Platform"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}

            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddPlatform}
            >
              Add Platform
            </Button>
          </Stack>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ marginRight: 2 }}
        >
          Save
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusiness;

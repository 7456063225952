import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
// import { Authenticator } from '@aws-amplify/ui-react';
//import ProTip from './ProTip';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import RouteGuard from "./RouteGuard"; // Import your custom route guard
import Header from "./Components/Header/Header";
import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//ComingSoon
import ComingSoon from "./Pages/AdditionalPages/ComingSoon";

//Pricing
import Pricing from "./Pages/Pricing/Pricing";
import SubscriptionSuccess from "./Pages/Pricing/SubscriptionSuccess";

import Home from "./Pages/Start/StartPage";
import StartBusinessPage from "./Pages/StartBusinessPage/StartBusinessPage";
//Auth
import SignIn from "./Pages/Auth/SignIn";
import SignOut from "./Pages/Auth/SignOut";
//import SignUp from './Pages/Auth/SignUp';
import SignInSignUpPage from "./Pages/Auth/SignInSignUpPage";
//UserProfile
import UserProfile from "./Pages/UserProfile/UserProfile";

//ProvidersSerachResults
import ProvidersSerachResults from "./Pages/ProvidersSerachResults/Provider/ProvidersSerachResults";
import UrlSerachResults from "./Pages/ProvidersSerachResults/Url/UrlSerachResults";
import BusinessNameSerachResults from "./Pages/ProvidersSerachResults/BusinessName/BusinessNameSerachResults";
import RestaurantsSerachResults from "./Pages/ProvidersSerachResults/Restaurants/RestaurantsSerachResults";

//UsersSerachResults
import UserSearchResults from "./Pages/MyNetwork/UserSearchResults";

//Dashboard
import UserDashboard from "./Pages/SmartHome/UserDashboard";
import UserDashboard2 from "./Pages/SmartHome/UserDashboard2";
import UserDashboard3 from "./Pages/SmartHome/UserDashboard3";

//ProviderProfile
import ProviderProfile from "./Pages/ProviderProfile/ProviderProfile";

//Business page unauth
import BusinessProfile from "./Pages/BusinessUnauth/ProviderProfile";

//Chat
import Chat from "./Pages/Chat/Chat";

//Notifications
import Notifications from "./Pages/Notifications/Notifications";

//MyNetwork
import MyNetwork from "./Pages/MyNetwork/MyNetwork";

//Businesses
import BusinessCreate from "./Pages/BusinessCreate/BusinessCreate";
import BusinessSolicit from "./Pages/BusinessSolicit/BusinessSolicit";

import BusinessAdminPage from "./Pages/BusinessAdmin/BusinessAdminPage";

//UrlReviews
import RequestForm from "./Pages/UrlReviews/RequestForm";
import RequestDetailPage from "./Pages/UrlReviews/RequestDetailPage";
import WriteUrlReview from "./Pages/UrlReviews/WriteUrlReview";
import ReviewUrlCommentsPage from "./Pages/UrlReviews/ReviewCommentsPage";

//feedback
//import WriteReview from './Pages/ProviderProfile/WriteReview';
import WriteReview from "./Components/WriteReview/WriteReview";
import ReviewCommentsPage from "./Pages/ProviderProfile/ReviewCommentsPage";
import Footer from "./Components/Footer/Footer";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Revfer
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  console.log("authstatus in app.js", authStatus);
  return (
    <Box>
      <Header />
      <Box sx={{ mt: 8, bgcolor: "#F8F9F9" }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/auth/signin" element={<SignIn />} />
          <Route exact path="/auth/signout" element={<SignOut />} />
          <Route                                                  
            path="/business/:businessId/:title"
            element={<BusinessProfile />}
          />
          <Route
            exact
            path="/landing/business"
            element={<StartBusinessPage />}
          />

          <Route path="/auth/signinsignup" element={<SignInSignUpPage />} />

          <Route
            path="/comingsoon"
            element={
              <RouteGuard redirectTo="/auth/signin">
                <ComingSoon />
              </RouteGuard>
            }
          />

          <Route
            path="/pricing"
            element={
              <RouteGuard redirectTo="/auth/signin">
                <Pricing />
              </RouteGuard>
            }
          />

          <Route
            path="/subscription-success"
            element={
              <RouteGuard redirectTo="/auth/signin">
                <SubscriptionSuccess />
              </RouteGuard>
            }
          />

          <Route
            path="/feedback"
            element={
              <RouteGuard redirectTo="/auth/signin">
                <WriteReview />
              </RouteGuard>
            }
          />

          <Route
            path="/business/admin/:businessId"
            element={
              <RouteGuard redirectTo="/auth/signin">
                <BusinessAdminPage />
              </RouteGuard>
            }
          />

          <Route path="/home" element={<UserDashboard />} />
          <Route path="/home2" element={<UserDashboard2 />} />
          <Route path="/dashboard" element={<UserDashboard3 />} />
          <Route path="/profile/:userId" element={<UserProfile />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/provider/profile/:spId" element={<ProviderProfile />} />
          <Route
            path="/search/businessname/results"
            element={<BusinessNameSerachResults />}
          />
          <Route
            path="/search/providers/results"
            element={<ProvidersSerachResults />}
          />
          <Route
            path="/search/restaurants/results"
            element={<RestaurantsSerachResults />}
          />

          <Route
            path="/review/comments/:reviewId"
            element={<ReviewCommentsPage />}
          />

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/company/setup/new" element={<BusinessCreate />} />
          <Route path="/company/setup/solicit" element={<BusinessSolicit />} />

          <Route path="/mynetwork" element={<MyNetwork />} />
          <Route path="/search/users/results" element={<UserSearchResults />} />
          <Route path="/search/urls/results" element={<UrlSerachResults />} />

          <Route path="/url/create" element={<RequestForm />} />
          <Route
            path="/url/request/:requestId"
            element={<RequestDetailPage />}
          />
          <Route path="/url/request/review" element={<WriteUrlReview />} />
          <Route
            path="/url/request/review/comments"
            element={<ReviewUrlCommentsPage />}
          />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
}

import React from 'react';
import { Field, Formik, Form } from 'formik';
import { TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';

const BusinessNamePage = ({formData}) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Enter Your Business Name
      </Typography>
      <Field
        as={TextField}
        name="businessName"
        label="Business Name"
        variant="outlined"
        fullWidth
        required
        defaultValue={formData.businessName}
      />

      {/* Checkbox for owner/representative */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Field
          type="checkbox"
          name="isRepresentative"
          as={Checkbox}
        />
        <Typography variant="body2">
        I am the owner or authorized representative of this business.
        </Typography>
      </div>
    </>
  );
};

export default BusinessNamePage;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';

const SignOut = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  console.log('authStatus in signout.js', authStatus);

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      navigate('/auth/signin'); // Redirect to /home after successful login
    }
  }, [authStatus, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '90vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Render the sign-in/sign-up modal */}
      <h1 style={{ fontSize: '20px' }}>Hey, Signing out...</h1>
      {/* Access user's name property if available */}
    </div>
  );
};

export default SignOut;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePrefetch as useGetUserProfilePrefetch } from '../../redux/thunks/users/userprofile';



const SimilarProfiles = ({ user }) => {

  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const prefetchGetUserProfile = useGetUserProfilePrefetch('getUserProfile')

  const visibleUsers = showAll
    ? user.similarProfiles.userDetails
    : user.similarProfiles.userDetails.slice(0, 4);



  const handleNavigation2Profile = (id) => {
    // Define navigation logic for endorsed activity (e.g., go to profile page)
    navigate(`/profile/${id}`);
  };

  useEffect(() => {
    if (visibleUsers.length > 0) {
      // Loop through currentFriends and prefetch their profiles
      visibleUsers.forEach((friend) => {
        // Call the prefetch function for each user profile
        prefetchGetUserProfile(friend.id);
      });
    }
  }, [visibleUsers]);

  return (
    <div>
      <Typography variant="subtitle2" sx={{ fontWeight: '800' }}>
        Similar Profiles
      </Typography>
      {visibleUsers.map((user) => (
        <Box
          key={user.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
            borderBottom: '1px solid #e0e0e0',

          }}
        >
          <Avatar
            src={user.profileImageUrl}
            alt={user.displayName}
            sx={{
              width: '50px',
              height: '50px',
              marginRight: 2,
            }}
          />
          <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} onClick={() => handleNavigation2Profile(user.id)}>{user.displayName}</Typography>
        </Box>
      ))}
      {!showAll && user.similarProfiles.userDetails.length > 4 && (
        <Button
          onClick={toggleShowAll}
          sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
        >
          See All
        </Button>
      )}
      {showAll && (
        <Button
          onClick={toggleShowAll}
          sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
        >
          Show Less
        </Button>
      )}
    </div>
  );
};

export default SimilarProfiles;

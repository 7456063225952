import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import SearchComponent from "../../../Components/SearchComponent/SearchComponent";
import ResultsSection from "../ResultsSection";

import UrlFilters from "../FilterTypes/UrlFilters";
import PhotoCard from "../../../Components/ByteSize/PhotoCards/PhotoCard";
import Footer from "../../../Components/Footer/Footer";

import { useSpFindMutation } from "../../../redux/thunks/sp/spfind";
import { useSpFindBusinessNameMutation } from "../../../redux/thunks/sp/spfindbusinessname";
import { useUrlRequestSearchMutation } from "../../../redux/thunks/urls/urlrequestsearch";

import FiltersSection from "../FiltersSection";
import SortSection from "../SortSection";

/*
Logic notes: Every type of search will have its own primary landing page and then also support other type of searches. 
Search pages, use useeffect for each search then passes latest results to filters and the from filtered results to pagination and sort.
Think of the way to make a component that includes all this functionality and we dont have to repaet it everwhere. 

*/

const UrlSerachResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  const searchResults = location.state?.searchResultsUrl || [];
  const pageSize = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredResults, setFilteredResults] = useState(
    searchResults.slice(0, pageSize)
  );
  const [pagifiedResults, setPagifiedResults] = useState(
    searchResults.slice(0, pageSize)
  );
  const [latestResults, setLatestResults] = useState(searchResults);

  const [triggerSearch, { data: newResults, isLoading }] = useSpFindMutation();
  const [
    triggerSearchBusinessName,
    { data: newResultsBusinessName, isLoadingBusinessName },
  ] = useSpFindBusinessNameMutation();
  const [
    triggerSearchUrl,
    { data: searchResultsUrl, isLoading: isUrlSearchLoading },
  ] = useUrlRequestSearchMutation();

  console.log("in url search results", searchResults);

  useEffect(() => {
    if (newResults) {
      setLatestResults(newResults);
      setPagifiedResults(newResults.slice(0, pageSize));
    }
  }, [newResults]);

  useEffect(() => {
    if (searchResultsUrl) {
      setLatestResults(searchResultsUrl);
      setPagifiedResults(searchResultsUrl.slice(0, pageSize));
    }
  }, [searchResultsUrl]);

  useEffect(() => {
    if (newResultsBusinessName) {
      setLatestResults(newResultsBusinessName);
      setPagifiedResults(newResultsBusinessName.slice(0, pageSize));
    }
  }, [newResultsBusinessName]);

  useEffect(() => {
    if (filteredResults) {
      setPagifiedResults(filteredResults.slice(0, pageSize));
      console.log("filteredResults in useeffect", filteredResults);
    }
  }, [filteredResults]);

  const handleSearch = async (searchText, tabValue) => {
    // Handle the search with searchText and tabValue
    console.log("Search Text:", searchText);
    console.log("tabValue:", tabValue);
    let auth = {
      token: token,
      authType: "token",
    };
    const payload = {
      auth,
      text: searchText,
    };
    if (tabValue === "Categories") {
      await triggerSearch({ payload });
    }
    if (tabValue === "Products") {
      await triggerSearchUrl({ payload });
    }
    if (tabValue === "BusinessName") {
      await triggerSearchBusinessName({ payload });
    }
  };

  const handlePageChange = (newPage) => {
    const startIndex = (newPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPage(newPage);
    setPagifiedResults(filteredResults.slice(startIndex, endIndex));
  };

  if (isLoading || isUrlSearchLoading || isLoadingBusinessName) {
    // Show a loading spinner if data isloading
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      {/* First Full-Width Row with Border */}
      <Paper
        variant="outlined"
        style={{ marginBottom: "0px", padding: "0px", border: "none" }}
      ></Paper>

      {/* Second Full-Width Row with Border */}
      <Paper
        variant="outlined"
        style={{ marginBottom: "0px", paddingTop: "20px", border: "none" }}
      >
        <SearchComponent onSearch={handleSearch} />
      </Paper>

      {/* 3 Columns */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={2}>
          {/* First Column (30%) */}
          <Paper variant="outlined" sx={{ padding: "10px", border: "none" }}>
            <FiltersSection
              results={latestResults}
              setFilteredResults={setFilteredResults}
              filter={"url"}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          {/* Second Column (70%) */}
          <Paper variant="outlined" style={{ padding: "20px", border: "none" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: "8px" }}
              >
                Showing {currentPage * pageSize - pageSize + 1} -{" "}
                {Math.min(currentPage * pageSize, filteredResults.length)} of{" "}
                {filteredResults.length}
              </Typography>
              {filteredResults.length > 0 && (
                <SortSection
                  results={filteredResults}
                  setFilteredResults={setFilteredResults}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </Box>
            <ResultsSection searchResults={pagifiedResults} type={"url"} />
            <Box mt={2}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  disabled={currentPage * pageSize >= filteredResults.length}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          {/* First Column (30%) */}
          <Paper variant="outlined" sx={{ padding: "10px", border: "none" }}>
            <PhotoCard type={"StartBusiness"} />
          </Paper>
          {/* <Paper variant="outlined" sx={{ padding: '10px', border: 'none'  }}>
            <Footer/>
          </Paper> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UrlSerachResults;
